<template>
  <div class="talentsData">
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left"></div>
        <!-- 搜索框 -->
        <el-input
          placeholder="根据日期或者名字查询"
          v-model="queryInfo.nametime"
          style="width: 470px"
          @change="getSchemes1"
        >
          <template slot="append">
            <div @click="getSchemes1" style="cursor: pointer">搜索</div>
          </template>
        </el-input>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
      >
        <el-table-column prop="adminName" label="用户账号"></el-table-column>
        <el-table-column prop="operationContext" label="日志内容"></el-table-column>
        <el-table-column prop="operationTime" label="操作时间"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import { getNamedate } from '@/api/system.js'
export default {
  created() {
    this.getTechnologys()
  },
  data() {
    return {
      // 查询搜索数据
      // 表格数据
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        order: 1,
      },
      dataData: [],
      total: 0,
      input2: '',
    }
  },
  methods: {
    // 按上传时间显示
    async uploadTime() {
      if (this.queryInfo.order == 1) {
        this.queryInfo.order = 0
        this.getTechnologys()
      } else {
        this.queryInfo.order = 1
        this.getTechnologys()
      }
    },
    // 浏览人才数据
    getSchemes1() {
      let nametime = this.queryInfo.nametime
      this.queryInfo = {
        nametime: nametime,
        pageSize: 10,
        pageNum: 1,
        order: 1,
      }
      this.getTechnologys()
    },
    async getTechnologys() {
      const res = await getNamedate(this.queryInfo)
      if (res.code === 200) {
        this.dataData = res.data.list
        this.total = res.data.total
        this.dataData.forEach((el) => {
          if (el.integrity) {
            el.integrity = Number(el.integrity.slice(0, -1))
          } else {
            el.integrity = 0
          }
        })
      }
    },
    // 按姓名搜索
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getTechnologys()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getTechnologys()
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
  // position: relative;
  /deep/ .el-button {
    padding: 10px 10px;
  }
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
/deep/ .el-dialog {
  min-height: 100px;
  overflow: auto;
  width: 50% !important;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
